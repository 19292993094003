import { ContentFragment, ContentStatus } from '@oneaudi/falcon-tools';

export const FA_LOCATOR_ID = `fa-editorial-media`;
export const FA_LOCATOR = `[data-test-id=${FA_LOCATOR_ID}]`;

/**
 * FeatureAppLoader attributes for loading the video feature app
 */
export const VIDEO_PLAYER_FA_NAME = 'fa-video-player';
export const videoPlayerFeatureAppParams = (() => {
  const videoPlayerFeatureAppVersion = 'v5.2.1';
  const videoPlayerFeatureAppEnv = 'prod';
  const videoPlayerFeatureAppBaseUrl = `https://${VIDEO_PLAYER_FA_NAME}.cdn.${videoPlayerFeatureAppEnv}.collab.apps.one.audi/${videoPlayerFeatureAppVersion}`;
  return {
    serverSrc: `${videoPlayerFeatureAppBaseUrl}/fh/app.node.js`,
    src: `${videoPlayerFeatureAppBaseUrl}/fh/app.js`,
    // src: `http://localhost:3000/${VIDEO_PLAYER_FA_NAME}/app/fh/app.js`, // local development only, use local dev version of fa-video
  };
})();

export type Theme = 'Dark' | 'Light';

export type ProportionTypes =
  | '1-1 Same Size'
  | '2-1 (Landscape-Portrait)'
  | '1-2 (Portrait-Landscape)';

export type AssetType = AssetTypeImage | AssetTypeVideo;

export interface AssetTypeImage {
  assetType: 'image';
  src: {
    assetValue: string;
  };
  srcPortrait?: {
    assetValue: string;
  };
  alt: string;
}

export interface AssetTypeVideo {
  assetType: 'video';
  src: {
    assetValue: string;
  };
  srcPortrait?: {
    assetValue: string;
  };
  alt: string;
  poster?: {
    assetValue: string;
  };
  autoPlay?: boolean;
  loop?: boolean;
  muted?: boolean;
  controls?: boolean;
  preserveVideoWidth?: boolean;
}

export interface EditorJsonContent {
  __type?: 'editor.json'; // This will in reality never be set, but it makes mapping easier

  media1Option: {
    asset: AssetTypeImage | AssetTypeVideo;
    caption?: string;
    consumptionAndEmissions?: string[];
    disclaimers?: string[];
  };
  media2Option?: {
    asset: AssetTypeImage | AssetTypeVideo;
    caption?: string;
    consumptionAndEmissions?: string[];
    disclaimers?: string[];
    proportion?: ProportionTypes;
  };
  themeOption?: {
    theme?: Theme;
  };
  featureAppMarginOption?: {
    marginTop?: boolean;
    marginBottom?: boolean;
  };
}

export interface AssetContentFragment extends Partial<ContentFragment> {
  title: string;
  description: string;
  id: string;
  path: string;
  contentPath: string;
  created: Date;
  status: ContentStatus;
  fields: {
    assetType: 'image' | 'video';
    src: {
      path: string;
      contentPath: string;
      assetId: string;
      sizeInBytes: number;
      created: Date;
      status: ContentStatus;
    };
    srcPortrait: {
      path: string;
      contentPath: string;
      assetId: string;
      sizeInBytes: number;
      created: Date;
      status: ContentStatus;
    };
    alt: string;
    poster: {
      path: string;
      contentPath: string;
      assetId: string;
      sizeInBytes: number;
      created: Date;
      status: ContentStatus;
    };
    autoPlay: boolean;
    loop: boolean;
    muted: boolean;
    controls: boolean;
    preserveVideoWidth: boolean;
  };
}

export interface FalconContent extends Partial<ContentFragment> {
  __type: 'aem-headless';
  fields: {
    media1Option_asset: AssetContentFragment;
    media1Option_caption?: string;
    media1Option_consumptionAndEmissions?: string[];
    media1Option_disclaimers?: string[];
    media2Option_asset: AssetContentFragment;
    media2Option_caption?: string;
    media2Option_consumptionAndEmissions?: string[];
    media2Option_disclaimers?: string[];
    media2Option_proportion?: ProportionTypes;
    themeOption_theme: Theme;
    featureAppMarginOption_marginTop?: boolean;
    featureAppMarginOption_marginBottom?: boolean;
  };
}

export interface AppContent {
  media1: AssetTypeImage | AssetTypeVideo;
  caption1?: string;
  consumptionAndEmissions1?: string[];
  disclaimers1?: string[];
  media2?: AssetTypeImage | AssetTypeVideo;
  caption2?: string;
  consumptionAndEmissions2?: string[];
  disclaimers2?: string[];
  proportion?: ProportionTypes;
  theme: Theme;
  hasMarginTop?: boolean;
  hasMarginBottom?: boolean;
}
